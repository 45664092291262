import _define_property from "@swc/helpers/src/_define_property.mjs";
import { useQueryClient } from "react-query";
import { PAGES, API } from "@vfit/shared/data-access";
var _obj;
export var CMS_CONFIG = (_obj = {}, _define_property(_obj, PAGES.CONSUMER, {
    apis: [
        {
            key: "getMobileLinePayments",
            api: API.CMS_GET_PAYMENTS_MOBILE
        },
        {
            key: "getDeliveryInformationMobile",
            api: API.CMS_GET_DELIVERY_INFORMATION_MOBILE
        },
        {
            key: "getPageNavigation",
            api: API.CMS_GET_PAGE_NAVIGATION
        },
        {
            key: "getCoverageToolConfig",
            api: API.CMS_GET_COVERAGE_TOOL_CONFIG
        },
        {
            key: "getPortabilityCard",
            api: API.CMS_GET_PORTABILITY
        },
        {
            key: "getPortabilityCardMobile",
            api: API.CMS_GET_PORTABILITY_MOBILE
        },
        {
            key: "getWinbackNumberCard",
            api: API.CMS_GET_WINBACK_DATA
        },
        {
            key: "getWinbackConfiguration",
            api: API.CMS_GET_WINBACK_CONFIG
        },
        {
            key: "getFamilyPlusPayment",
            api: API.CMS_GET_FAMILY_PLUS_PAYMENT
        },
        {
            key: "getPersonalDataCard",
            api: API.CMS_GET_PERSONAL_DATA
        },
        {
            key: "getPersonalDataCardMobile",
            api: API.CMS_GET_PERSONAL_DATA_MOBILE
        },
        {
            key: "getBillingInformation",
            api: API.CMS_GET_BILLING_INFORMATION
        },
        {
            key: "getSupportModule",
            api: API.CMS_GET_SUPPORT_MODULE
        },
        {
            key: "getTermsAndConditionsCard",
            api: API.CMS_GET_TERMS_AND_CONDITIONS
        },
        {
            key: "getTermsAndConditionsCardMobile",
            api: API.CMS_GET_TERMS_AND_CONDITIONS_MOBILE
        },
        {
            key: "getDeliveryInformation",
            api: API.CMS_GET_DELIVERY_INFORMATION
        },
        {
            key: "getPayments",
            api: API.CMS_GET_PAYMENTS
        },
        {
            key: "getThankYouPage",
            api: API.CMS_GET_THANK_YOU_PAGE
        },
        {
            key: "getThankYouMobile",
            api: API.CMS_GET_THANK_YOU_PAGE_MOBILE
        },
        {
            key: "getMap",
            api: API.CMS_GET_MAP
        },
        {
            key: "getCmsTecnologies",
            api: API.CMS_GET_TECNOLOGIES
        },
        {
            key: "getCmsDevices",
            api: API.CMS_GET_DEVICES
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getPages",
            api: API.CMS_GET_PAGES_CONSUMER
        },
        {
            key: "getOrderError",
            api: API.CMS_GET_ORDER_ERROR
        },
        {
            key: "getSupportMobuleMobile",
            api: API.CMS_GET_SUPPORT_MODULE_MOBILE
        },
        {
            key: "getGenericErrorMobile",
            api: API.CMS_GET_GENERIC_ERROR_MOBILE
        },
        {
            key: "getOrderErrorMobile",
            api: API.CMS_GET_ORDER_ERROR_MOBILE
        },
        {
            key: "getEditAddressMobile",
            api: API.CMS_GET_EDIT_ADDRESS_MOBILE
        },
        {
            key: "getEditAddress",
            api: API.CMS_GET_EDIT_ADDRESS
        },
        {
            key: "getMapMobile",
            api: API.CMS_GET_MAP_MOBILE
        },
        {
            key: "getStepperAddressConfigMobile",
            api: API.CMS_GET_EDIT_ADDRESS_STEPPER_MOBILE
        },
        {
            key: "getStepperAddressConfig",
            api: API.CMS_GET_EDIT_ADDRESS_STEPPER
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        },
        {
            key: "getDeviceCharacteristics",
            api: API.CMS_GET_DEVICE_CHARACTERISTICS
        },
        {
            key: "getBrightSkyConfigurations",
            api: API.CMS_GET_BRIGHT_SKY_CONF
        },
        {
            key: "getClickToCallConfigurations",
            api: API.CMS_GET_CLICK_TO_CALL_CONF
        },
        {
            key: "getClickToCallAppConfigurations",
            api: API.CMS_GET_CLICK_TO_CALL_APP_CONF
        },
        {
            key: "getDroppedCartConfigurations",
            api: API.CMS_GET_DROPPED_CARD
        },
        {
            key: "getDroppedCartHomepageConfigurations",
            api: API.CMS_GET_DROPPED_CART_HOMEPAGE
        },
        {
            key: "getGlobalConfigurations",
            api: API.CMS_GET_GLOBAL_CONFIG
        },
        {
            key: "getStickyBannerCms",
            api: API.CMS_GET_STICKY_BANNER
        },
        {
            key: "getfloatingbannerCms",
            api: API.CMS_GET_FLOATING_BANNER
        },
        {
            key: "getFixedVouchersCms",
            api: API.CMS_GET_FIXED_VOUCHERS
        },
        {
            key: "getMobileVouchersCms",
            api: API.CMS_GET_MOBILE_VOUCHERS
        },
        {
            key: "getCustomerErrorFixed",
            api: API.CMS_GET_CUSTOMER_ERROR_FIXED
        },
        {
            key: "getCustomerErrorMobile",
            api: API.CMS_GET_CUSTOMER_ERROR_MOBILE
        },
        {
            key: "getVoucherInsertionManagentCms",
            api: API.CMS_GET_VOUCHER_INSERTION_MANAGMENT_CONF
        },
        {
            key: "getVoucherDiscount",
            api: API.CMS_GET_VOUCHER_DISCOUNT
        },
        {
            key: "getGlobalPromo",
            api: API.CMS_GET_GLOBAL_PROMO
        },
        {
            key: "getGlobalPromoIAdd",
            api: API.CMS_GET_GLOBAL_PROMO_IADD
        },
        {
            key: "getMultiFactorAuth",
            api: "landing/multi-factor-auth"
        },
        {
            key: "getPageNavigation",
            api: "landing/homepage/page-navigation"
        }, 
    ]
}), _define_property(_obj, PAGES.GRANDIAZIENDE_HOME_PAGE, {
    apis: [
        {
            key: "getPageNavigation_GA",
            api: API.CMS_GET_PAGE_GRANDIAZIENDE_NAVIGATION
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getPages",
            api: API.CMS_GET_PAGES_GRANDIAZIENDE
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.BUSINESS_HOME_PAGE, {
    apis: [
        {
            key: "getPageNavigation_BL",
            api: API.CMS_GET_PAGE_BUSINESS_NAVIGATION
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getPages",
            api: API.CMS_GET_PAGES_BUSINESS
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        }, 
    ]
}), _define_property(_obj, PAGES.LANDING_CONSUMER, {
    apis: [
        {
            key: "getPageNavigation",
            api: API.CMS_GET_PAGE_NAVIGATION
        },
        {
            key: "getFatturazione2830",
            api: API.CMS_GET_FATTURAZIONE2830
        },
        {
            key: "getVideoRecognition",
            api: API.CMS_GET_VIDEO_RECOGNITION
        },
        {
            key: "getReshapingAll",
            api: API.CMS_GET_RESHAPING_ALL
        },
        {
            key: "getReshapingProductCards",
            api: API.CMS_GET_RESHAPING_PRODUCT_CARDS
        },
        {
            key: "getOnboardingCommonViews",
            api: API.CMS_GET_ONBOARDING_VIEWS_COMMON
        },
        {
            key: "getOnboardingCommonModules",
            api: API.CMS_GET_ONBOARDING_MODULES_COMMON
        },
        {
            key: "getOnboardingDashboardCommon",
            api: API.CMS_GET_ONBOARDING_DASHBOARD_COMMON
        },
        {
            key: "getOnboardingWizardTVBox",
            api: API.CMS_GET_ONBOARDING_WIZARD_TVBOX
        },
        {
            key: "getOnboardingWizardPowerStation",
            api: API.CMS_GET_ONBOARDING_WIZARD_POWERSTATION
        },
        {
            key: "getOnboardingWizardAlternativeModemFTTH",
            api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_FTTH
        },
        {
            key: "getOnboardingWizardAlternativeModemADSL",
            api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_ADSL
        },
        {
            key: "getOnboardingWizardAlternativeModemVDSL",
            api: API.CMS_GET_ONBOARDING_WIZARD_ALTERNATIVE_MODEM_VDSL
        },
        {
            key: "getOnboardingWizardPowerWiFi",
            api: API.CMS_GET_ONBOARDING_WIZARD_POWERWIFI
        },
        {
            key: "getOnboardingWizardWifiExtender",
            api: API.CMS_GET_ONBOARDING_WIZARD_WIFIEXTENDER
        },
        {
            key: "getOnboardingWizardInvoiceHelp",
            api: API.CMS_GET_ONBOARDING_WIZARD_INVOICE_HELP
        },
        {
            key: "getOnBoardingAllDevices",
            api: API.CMS_GET_ONBOARDING_DEVICES
        },
        {
            key: "getOnBoardingProductCards",
            api: API.CMS_GET_ONBOARDING_PRODUCT_CARDS
        },
        {
            key: "getOnBoardingOfferCards",
            api: API.CMS_GET_ONBOARDING_OFFER_CARDS
        },
        {
            key: "getAllVouchers",
            api: API.CMS_GET_ONBOARDING_VOUCHERS
        },
        {
            key: "getTechAppointmentLandingPage",
            api: API.CMS_GET_TECH_APPOINTMENT
        },
        {
            key: "getAllStepperCardsForTech",
            api: API.CMS_GET_ONBOARDING_STEPPERCARDS_TECH
        },
        {
            key: "getOnboardingErrors",
            api: API.CMS_GET_ONBOARDING_ERRORS
        },
        {
            key: "getTechAppointmentFaq",
            api: API.CMS_GET_TECH_APPOINTMENT_FAQ
        },
        {
            key: "getAllLeads",
            api: API.CMS_GET_ALL_LEADS
        },
        {
            key: "getOneClick",
            api: API.CMS_GET_ONE_CLICK
        },
        {
            key: "getContractSummary",
            api: API.CMS_GET_CONTRACT_SUMMARY
        },
        {
            key: "getMultiFactorAuth",
            api: API.CMS_GET_MULTI_FACTOR_AUTH
        }, 
    ]
}), _define_property(_obj, PAGES.CONSUMER_ABROAD, {
    apis: [
        {
            key: "getNations",
            api: API.CMS_GET_NATIONS_CBU
        },
        {
            key: "getConsumerOffersFilter",
            api: API.CMS_CTO_GET_OFFERS_FILTER
        },
        {
            key: "getBusinessPlansOperator",
            api: API.CMS_CTO_GET_PLANS_OPERATOR_CBU
        },
        {
            key: "getConsumerWorldOffers",
            api: API.CMS_CTO_GET_WORLD_OFFERS
        },
        {
            key: "getPageNavigation",
            api: API.CMS_GET_PAGE_NAVIGATION
        },
        {
            key: "getGenericError",
            api: API.CMS_GET_GENERIC_ERROR
        },
        {
            key: "getTariffeEsteroConsumerHome",
            api: API.CMS_ABROAD_GET_HOME
        },
        {
            key: "getTariffeEsteroConsumerHomeV2",
            api: API.CMS_ABROAD_GET_HOME_V2
        },
        {
            key: "getTariffeEsteroConsumerRoamingAereo",
            api: API.CMS_ABROAD_GET_ROAMING_AEREO
        },
        {
            key: "getTariffeEsteroConsumerRoamingAereoV2",
            api: API.CMS_ABROAD_GET_ROAMING_AEREO_V2
        },
        {
            key: "getTariffeEsteroConsumerPaeseUe",
            api: API.CMS_ABROAD_GET_PAESE_UE
        },
        {
            key: "getTariffeEsteroConsumerPaeseUeV2",
            api: API.CMS_ABROAD_GET_PAESE_UE_V2
        },
        {
            key: "getTariffeEsteroConsumerDettaglioPaeseUe",
            api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_UE
        },
        {
            key: "getTariffeEsteroConsumerDettaglioPaeseUeV2",
            api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_UE_V2
        },
        {
            key: "getTariffeEsteroConsumerDettaglioPaeseMondo",
            api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO
        },
        {
            key: "getTariffeEsteroConsumerDettaglioPaeseMondoV2",
            api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_MONDO_V2
        },
        {
            key: "getTariffeEsteroConsumerDettaglioPaeseEnrichedV2",
            api: API.CMS_ABROAD_GET_DETTAGLIO_PAESE_ENRICHED_V2
        },
        {
            key: "getTariffeEsteroConsumerDettaglioTestEditingsV2",
            api: API.CMS_ABROAD_GET_DETTAGLIO_TEST_EDITINGS_V2
        },
        {
            key: "getTariffeEsteroEditorialConfigs",
            api: API.CMS_ABROAD_GET_EDITORIAL_CONFIG
        },
        {
            key: "getBusinessFixedLinePlaceholders",
            api: API.CMS_BFL_GET_COMMON_PLACEHOLDER
        }, 
    ]
}), _obj);
export var useCmsConfig = function(page, apiKey) {
    var apis = page.apis;
    var allData = {};
    apis === null || apis === void 0 ? void 0 : apis.forEach(function(api) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var client = useQueryClient();
        var data = client.getQueryData(api.key, {
            exact: false
        });
        allData[api.api] = data;
    });
    return apiKey ? allData[apiKey] : allData;
};
