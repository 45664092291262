import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import { recoverServiceabilityField, errorMock, BACKUP_OFFER_KEY, PLACE_ID, checkIsApp } from "@vfit/consumer/data-access";
import { API } from "@vfit/shared/data-access";
import { useMutation, useQueryClient } from "react-query";
import { LoggerInstance, nextClient } from "@vfit/shared/providers";
import { CoverageErrorType } from "@vfit/shared/models";
/**
 * Offering Serviceability Service for api offeringServiceability.
 * This method is used to retrieve the serviceability related to a specific address input.
 * @param payload
 * @param customOptions
 */ var offeringServiceabilityService = function(payload, customOptions) {
    return nextClient.post(API.OFFERING_SERVICEABILITY, payload, _object_spread({}, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.headers) && {
        headers: customOptions.headers
    }, (customOptions === null || customOptions === void 0 ? void 0 : customOptions.silentLoginHeaders) && {
        silentLoginHeaders: customOptions.silentLoginHeaders
    }));
};
/**
 * Custom mutation hook for run offeringServiceabilityService using the validateAddress service response as input.
 * Call this after validateAddress has been called.
 * @param offerId
 * @param allProducts
 * @param alternativeCmsIdList
 * @param place_id
 * @returns
 */ export var useServiceabilityAddressMutation = function(offerId, allProducts, alternativeCmsIdList, alternativeCmsIdListByTech, place_id) {
    var queryClient = useQueryClient();
    return useMutation("offeringServiceability", function(validatedAddress) {
        return offeringServiceabilityService({
            serviceabilityAddress: validatedAddress
        });
    }, {
        onSuccess: function(data) {
            if ((data === null || data === void 0 ? void 0 : data.serviceabilityResult) === "serviceable") {
                var ref;
                if (place_id) localStorage.setItem(PLACE_ID, place_id);
                var originalTechType = recoverServiceabilityField(data === null || data === void 0 ? void 0 : data.serviceabilityCharacteristic, "Technology");
                var idByTech = alternativeCmsIdListByTech === null || alternativeCmsIdListByTech === void 0 ? void 0 : alternativeCmsIdListByTech.filter(function(el) {
                    return (el === null || el === void 0 ? void 0 : el.technology.toLowerCase()) === originalTechType.toLowerCase();
                });
                var allLockinProducts = allProducts === null || allProducts === void 0 ? void 0 : allProducts.filter(function(el) {
                    return el === null || el === void 0 ? void 0 : el.isLockInProduct;
                });
                var lockinIds = allLockinProducts === null || allLockinProducts === void 0 ? void 0 : allLockinProducts.map(function(el) {
                    return el === null || el === void 0 ? void 0 : el.cmsId;
                });
                var alternativeProductList = [];
                var alternativeOffersList = [];
                alternativeCmsIdList === null || alternativeCmsIdList === void 0 ? void 0 : alternativeCmsIdList.forEach(function(alternativeCmsId) {
                    var foundedProd = allProducts === null || allProducts === void 0 ? void 0 : allProducts.find(function(el) {
                        return (el === null || el === void 0 ? void 0 : el.cmsId) == alternativeCmsId && (checkIsApp() || !(el === null || el === void 0 ? void 0 : el.isLockInProduct));
                    });
                    if (foundedProd === null || foundedProd === void 0 ? void 0 : foundedProd.offerId) {
                        var ref, ref1, ref2;
                        alternativeProductList.push({
                            cmsId: foundedProd === null || foundedProd === void 0 ? void 0 : (ref = foundedProd.cmsId) === null || ref === void 0 ? void 0 : ref.toString(),
                            offerId: foundedProd === null || foundedProd === void 0 ? void 0 : (ref1 = foundedProd.offerId) === null || ref1 === void 0 ? void 0 : ref1.toString()
                        });
                        alternativeOffersList.push(foundedProd === null || foundedProd === void 0 ? void 0 : (ref2 = foundedProd.offerId) === null || ref2 === void 0 ? void 0 : ref2.toString());
                    }
                });
                var isTechFWA = originalTechType.toLowerCase().includes("fwa");
                var isOutdoor = isTechFWA ? originalTechType.toLowerCase().includes("outdoor") : false;
                localStorage.setItem("isTechFWA", JSON.stringify(isTechFWA));
                localStorage.setItem("isOutdoor", JSON.stringify(isOutdoor));
                localStorage.setItem("techOffer", JSON.stringify(originalTechType));
                if (idByTech.length > 0 && (checkIsApp() || !(lockinIds === null || lockinIds === void 0 ? void 0 : lockinIds.includes(idByTech === null || idByTech === void 0 ? void 0 : (ref = idByTech[0]) === null || ref === void 0 ? void 0 : ref.id)))) {
                    var ref1, ref2, ref3;
                    var foundedOffer = data === null || data === void 0 ? void 0 : data.offeringServiceabilityItem.find(function(offer) {
                        var ref, ref1;
                        return (offer === null || offer === void 0 ? void 0 : (ref = offer.offering) === null || ref === void 0 ? void 0 : ref.id) === (idByTech === null || idByTech === void 0 ? void 0 : (ref1 = idByTech[0]) === null || ref1 === void 0 ? void 0 : ref1.id);
                    });
                    localStorage.setItem("offeringServiceability", JSON.stringify(data));
                    localStorage.setItem(BACKUP_OFFER_KEY, JSON.stringify({
                        offeringId: foundedOffer === null || foundedOffer === void 0 ? void 0 : (ref1 = foundedOffer.offering) === null || ref1 === void 0 ? void 0 : ref1.id,
                        cmsId: (alternativeProductList === null || alternativeProductList === void 0 ? void 0 : (ref2 = alternativeProductList.find) === null || ref2 === void 0 ? void 0 : (ref3 = ref2.call(alternativeProductList, function(el) {
                            var ref;
                            return (el === null || el === void 0 ? void 0 : el.offerId) == (foundedOffer === null || foundedOffer === void 0 ? void 0 : (ref = foundedOffer.offering) === null || ref === void 0 ? void 0 : ref.id);
                        })) === null || ref3 === void 0 ? void 0 : ref3.cmsId) || ""
                    }));
                    queryClient.setQueryData("offeringServiceability", data);
                } else {
                    var foundedOffer1 = data === null || data === void 0 ? void 0 : data.offeringServiceabilityItem.find(function(offer) {
                        var ref;
                        return (offer === null || offer === void 0 ? void 0 : (ref = offer.offering) === null || ref === void 0 ? void 0 : ref.id) === offerId;
                    });
                    // alternative product
                    if (!foundedOffer1 && (alternativeOffersList === null || alternativeOffersList === void 0 ? void 0 : alternativeOffersList.length) > 0) {
                        var ref4;
                        foundedOffer1 = (ref4 = data) === null || ref4 === void 0 ? void 0 : ref4.offeringServiceabilityItem.find(function(backupOffer) {
                            var ref, ref1;
                            return alternativeOffersList === null || alternativeOffersList === void 0 ? void 0 : alternativeOffersList.includes(backupOffer === null || backupOffer === void 0 ? void 0 : (ref = backupOffer.offering) === null || ref === void 0 ? void 0 : (ref1 = ref.id) === null || ref1 === void 0 ? void 0 : ref1.toString());
                        });
                    }
                    var unserviceableCharacteristic = data === null || data === void 0 ? void 0 : data.serviceabilityCharacteristic.filter(function(characteristic) {
                        return characteristic.displayName === "DisplayTechnologyName" && characteristic.displayValue === "3G 4G";
                    });
                    if (foundedOffer1 && !unserviceableCharacteristic[0]) {
                        var ref5, ref6, ref7;
                        localStorage.setItem("offeringServiceability", JSON.stringify(data));
                        localStorage.setItem(BACKUP_OFFER_KEY, JSON.stringify({
                            offeringId: foundedOffer1 === null || foundedOffer1 === void 0 ? void 0 : (ref5 = foundedOffer1.offering) === null || ref5 === void 0 ? void 0 : ref5.id,
                            cmsId: (alternativeProductList === null || alternativeProductList === void 0 ? void 0 : (ref6 = alternativeProductList.find) === null || ref6 === void 0 ? void 0 : (ref7 = ref6.call(alternativeProductList, function(el) {
                                var ref;
                                return (el === null || el === void 0 ? void 0 : el.offerId) === (foundedOffer1 === null || foundedOffer1 === void 0 ? void 0 : (ref = foundedOffer1.offering) === null || ref === void 0 ? void 0 : ref.id);
                            })) === null || ref7 === void 0 ? void 0 : ref7.cmsId) || ""
                        }));
                        queryClient.setQueryData("offeringServiceability", data);
                    } else {
                        queryClient.setQueryData("offeringServiceability", {
                            error: "ERROR on serviceabilityAddress",
                            errorCode: isTechFWA ? CoverageErrorType.FWA : CoverageErrorType.KO,
                            errorMessage: "The offer ".concat(offerId, " is not serviceable on this address")
                        });
                    }
                }
            } else {
                localStorage.removeItem(PLACE_ID);
                queryClient.setQueryData("offeringServiceability", errorMock("offeringServiceability", {
                    status: "200",
                    statusText: "200",
                    url: API.OFFERING_SERVICEABILITY
                }, "The address is not serviceable."));
            }
        },
        onError: function(error) {
            LoggerInstance.error("ERROR - offeringServiceability: ", error);
            queryClient.setQueryData("offeringServiceability", {
                error: "ERROR on serviceabilityAddress",
                errorCode: CoverageErrorType.ERROR,
                errorMessage: "The offer ".concat(offerId, " is not serviceable on this address")
            });
            localStorage.setItem("offeringServiceability", JSON.stringify(errorMock("offeringServiceability")));
        }
    });
};
